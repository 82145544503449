import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { CloseIcon } from '../../../images/shapes';
import TopbarButton from './base/TopbarButton';

const CancelButton = ({ link, ...props }) => {
  const { t } = useTranslation();

  return (
    <TopbarButton
      link={link}
      buttonColor="blueBordered"
      label={t('Global.Cancel')}
      iconImage={<CloseIcon />}
      {...props}
    />
  );
};

export default CancelButton;

CancelButton.propTypes = {
  /**
   * Link to parent page
   */
  link: PropTypes.string,
};

CancelButton.defaultProps = {
  link: '',
};
