import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';
import PropTypes from 'prop-types';

// :: Component
import Button from '../../components/Button/Button';

// :: Hooks
import useSelectedSpace from '../../hooks/useSelectedSpace';

// :: Helpers
import { getTestProps } from '../../lib/helpers';

// :: Images
import { WarningIcon, ZoomMaleWorkingImage } from '../../images/shapes';
import PageLayout from '../../layout/PageLayout/PageLayout';
import TopbarBreadcrumbs from '../../components/Topbar/breadcrumbs/TopbarBreadcrumbs';

const ContentEmpty = ({ testId }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { buildUrlWithSpace } = useSelectedSpace();

  return (
    <PageLayout
      page="content"
      title={t('Global.Content')}
      breadcrumbs={<TopbarBreadcrumbs />}
    >
      <div
        className={twMerge(
          'flex flex-col',
          'items-center justify-center',
          'bg-white dark:bg-slate-950 w-full rounded-lg p-6 lg:px-12',
        )}
      >
        <ZoomMaleWorkingImage className="flex h-48" />

        <div className="w-full flex flex-col justify-center items-center">
          <p
            className={twMerge(
              'flex items-center text-indigo-950 dark:text-white my-8 font-bold ',
              'text-2xl lg:text-4xl xl:text-5xl xl:leading-[52px]',
            )}
          >
            <WarningIcon className="text-red h-10 mr-3" />
            {t('Global.ContentNoItemInTheMenu')}
          </p>

          <Button
            onClick={() =>
              navigate(buildUrlWithSpace('content-type-definitions'))
            }
            {...getTestProps(testId, 'back-definition-builder')}
          >
            {`${t('Global.BackTo')} ${t('Global.DefinitionBuilder')}`}
          </Button>
        </div>
      </div>
    </PageLayout>
  );
};

export default ContentEmpty;

ContentEmpty.propTypes = {
  /**
   * Page test id
   */
  testId: PropTypes.string,
};

ContentEmpty.defaultProps = {
  testId: '',
};
