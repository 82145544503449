import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { FloppyDiskIcon } from '../../../images/shapes';
import Loader from '../../Loader/Loader';
import TopbarButton from './base/TopbarButton';
import { useCallback } from 'react';

const SaveButton = ({ form, isLoading, navigateOnSave, label, ...props }) => {
  const { t } = useTranslation();

  const onClick = useCallback(() => {
    if (navigateOnSave) navigateOnSave.current = false;
  }, [navigateOnSave]);

  return (
    <TopbarButton
      buttonColor="blue"
      iconImage={
        isLoading ? (
          <Loader size="small" type="spinner-grid" />
        ) : (
          <FloppyDiskIcon />
        )
      }
      label={label || t('Global.Save')}
      onClick={onClick}
      {...(form ? { form, type: 'submit' } : {})}
      {...props}
    />
  );
};

export default SaveButton;

SaveButton.propTypes = {
  /**
   * Form id, if not provided form submission should be handled by onClick property
   */
  form: PropTypes.string,
  /**
   * Reference to navigate on save property
   */
  navigateOnSave: PropTypes.any,
  /**
   * If save form is in progress
   */
  isLoading: PropTypes.bool,
  /**
   * Button label, default "Save"
   */
  label: PropTypes.node,
};

SaveButton.defaultProps = {
  isLoading: false,
};
