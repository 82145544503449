import { useCallback, useContext, useMemo, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useModals } from '../../contexts/ModalContext';
import UserContext from '../../contexts/UserContext';
import DirtyHandlerContext from '../../contexts/DirtyHandlerContext';
import { usePlan } from '../../hooks/api';
import useApiErrorsToast from '../../hooks/api/useApiErrorsToast';
import useToken from '../../hooks/useToken';
import useFirstLoading from '../../hooks/useFirstLoading';
import Heading from '../../components/Heading/Heading';
import Loader from '../../components/Loader/Loader';
import ContentObjectInformations from '../../components/ContentObjectInformations/ContentObjectInformations';
import PlanForm from '../../form/PlanForm/PlanForm';
import { postPlan } from '../../lib/flotiq-client';
import {
  checkResponseStatus,
  ResponseError,
} from '../../lib/flotiq-client/response-errors';
import { getTestProps } from '../../lib/helpers';
import { WarningIcon } from '../../images/shapes';
import useSelectedSpace from '../../hooks/useSelectedSpace';
import { useGridNavigate } from '../../components/DataGrid/useGridFilters';
import PageLayout from '../../layout/PageLayout/PageLayout';
import TopbarBreadcrumbs from '../../components/Topbar/breadcrumbs/TopbarBreadcrumbs';
import CancelButton from '../../components/Topbar/buttons/CancelButton';
import SaveButton from '../../components/Topbar/buttons/SaveButton';
import TopbarActionMenu from '../../components/Topbar/buttons/base/TopbarActionMenu';
import SaveAndLeaveButton from '../../components/Topbar/buttons/SaveAndLeaveButtons';
import DeleteButton from '../../components/Topbar/buttons/DeleteButton';

const AddPlan = ({ testId }) => {
  const { t } = useTranslation();
  const jwt = useToken();
  const { space } = useSelectedSpace();

  const modal = useModals();
  const navigate = useNavigate();
  const { isRoleAdmin } = useContext(UserContext);
  const navigateOnSave = useRef();
  const { setDirty } = useContext(DirtyHandlerContext);

  const { id: planId } = useParams();

  const [isSaving, setIsSaving] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const { navigateGrid, gridLink } = useGridNavigate('plans', '/plans');

  const planOptions = useMemo(
    () => ({
      pause: !isRoleAdmin,
    }),
    [isRoleAdmin],
  );

  const {
    entity: plan,
    isLoading: planIsLoading,
    updateEntity: updatePlan,
    deleteEntity: deletePlan,
    status: planStatus,
    errors: planErrors,
  } = usePlan(planId, null, planOptions);

  const firstLoading = useFirstLoading(!planId || !planIsLoading, planId);

  useApiErrorsToast(planErrors);

  const handleSave = useCallback(
    async (data) => {
      setIsSaving(true);
      try {
        const { body, status } = await postPlan(jwt, space, data);
        checkResponseStatus(body, status);
        toast.success(t('Plans.Form.Added'));
        setIsSaving(false);
        navigate(`/plans/edit/${body.id}`);
        return [[body, {}], false];
      } catch (error) {
        if (!(error instanceof ResponseError)) {
          toast.error(t('Form.CommunicationErrorMessage'));
          setIsSaving(false);
          return [[data, {}], true];
        }
        toast.error(t('Plans.Form.CouldntAdd'));
        setIsSaving(false);
        return [[data, error.errors], true];
      }
    },
    [jwt, space, navigate, t],
  );

  const handleUpdate = useCallback(
    async (data) => {
      setIsSaving(true);
      try {
        const { body, status } = await updatePlan(data);

        checkResponseStatus(body, status);
        toast.success(t('Plans.Form.Updated'));
        setIsSaving(false);
        return [[data, {}], false];
      } catch (error) {
        if (!(error instanceof ResponseError)) {
          toast.error(t('Form.CommunicationErrorMessage'));
          setIsSaving(false);
          return [[data, {}], true];
        }

        toast.error(t('Plans.Form.CouldntUpdate'));
        setIsSaving(false);
        return [[data, error.errors], true];
      }
    },
    [t, updatePlan],
  );

  const handleDeletePlan = useCallback(async () => {
    modal.deleting('delete-modal');
    try {
      const { body, status } = await deletePlan(planId);
      checkResponseStatus(body, status);
      toast.success(t('Plans.Form.Deleted'));

      setDirty(false);
      navigateGrid();
    } catch (error) {
      if (!(error instanceof ResponseError)) {
        toast.error(t('Form.CommunicationErrorMessage'));
        return;
      }
      toast.error(t('Plans.Form.CouldntDelete'));
    }
  }, [deletePlan, modal, navigateGrid, planId, setDirty, t]);

  const handleDelete = useCallback(async () => {
    setIsDeleting(true);
    await modal.delete(t('Plans.ConfirmDelete'), 'delete-modal', () =>
      handleDeletePlan(),
    );
    setIsDeleting(false);
  }, [handleDeletePlan, modal, t]);

  const isFormDisabled = isSaving || isDeleting;

  const showForm = !firstLoading && (plan || !planId) && isRoleAdmin;

  const noAccess = useMemo(() => {
    return (
      <Heading
        level={2}
        additionalClasses="text-3xl md:text-4xl leading-8 dark:text-white"
      >
        <div
          className="flex flex-col items-center justify-center text-center"
          {...getTestProps(testId, 'empty-data')}
        >
          <WarningIcon className="text-red w-14 md:w-20 mb-3" />
          {t('Global.NoAccess')}
        </div>
      </Heading>
    );
  }, [t, testId]);

  const emptyResult = useMemo(() => {
    if (!isRoleAdmin) {
      return noAccess;
    }

    if (showForm) return null;
    if (firstLoading) {
      return (
        <Loader
          size="small"
          type="spinner-grid"
          {...getTestProps(testId, 'loader', 'testId')}
        />
      );
    }

    return (
      <Heading
        level={2}
        additionalClasses="text-3xl md:text-4xl leading-8 dark:text-white"
      >
        <div
          className="flex flex-col items-center justify-center text-center"
          {...getTestProps(testId, 'empty-data')}
        >
          <WarningIcon
            className="text-red w-14 md:w-20 mb-3"
            title={t('Plans.CouldntFind')}
          />
          {planStatus === 404
            ? t('Plans.Form.CouldntFind', { planId })
            : t('Form.CommunicationErrorMessage')}
        </div>
      </Heading>
    );
  }, [
    firstLoading,
    isRoleAdmin,
    noAccess,
    planId,
    planStatus,
    showForm,
    t,
    testId,
  ]);

  return (
    <PageLayout
      page="plans"
      title={!planId ? t('Plans.Form.AddPlan') : t('Plans.Form.EditPlan')}
      buttonsDisabled={isSaving || isDeleting}
      breadcrumbs={
        <TopbarBreadcrumbs
          parentTitle={t('Global.Plans')}
          parentLink={gridLink}
        />
      }
      buttons={
        isRoleAdmin ? (
          <>
            <CancelButton link={gridLink} />
            <SaveButton
              form="plan-form"
              isLoading={isSaving}
              navigateOnSave={navigateOnSave}
            />
            <TopbarActionMenu>
              <SaveAndLeaveButton
                form="plan-form"
                navigateOnSave={navigateOnSave}
              />
              {planId && <DeleteButton onClick={handleDelete} />}
            </TopbarActionMenu>
          </>
        ) : null
      }
      testId={testId}
    >
      <div className="flex flex-col w-full">
        {showForm ? (
          <div className="grid grid-cols-1 lg:grid-cols-3 xl:grid-cols-4 h-full mt-7">
            <div className="md:col-span-3 bg-white dark:bg-slate-950 rounded-lg mx-4 xl:ml-7 xl:mr-3.5 mb-7 relative">
              <PlanForm
                plan={plan}
                onSubmit={planId ? handleUpdate : handleSave}
                disabled={isFormDisabled}
                navigateOnSave={navigateOnSave}
                testId={testId}
              />
            </div>
            <div
              className="px-4 xl:pl-3.5 xl:pr-7 pb-7 border-t md:border-t-0 md:border-l dark:border-slate-800
              flex flex-col gap-5 w-full"
            >
              <ContentObjectInformations
                createdAt={plan?.createdAt}
                updatedAt={plan?.updatedAt}
                testId={testId}
              />
            </div>
          </div>
        ) : (
          <div
            className="flex flex-col items-center justify-center h-full bg-white dark:bg-slate-950
            rounded-lg m-4 xl:m-12"
          >
            {emptyResult}
          </div>
        )}
      </div>
    </PageLayout>
  );
};
export default AddPlan;

AddPlan.propTypes = {
  /**
   * Test id for add plan page
   */
  testId: PropTypes.string,
};

AddPlan.defaultProps = {
  testId: '',
};
