import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { TrashSolidIcon } from '../../../images/shapes';
import TopbarActionButton from './base/TopbarActionButton';

const DeleteButton = ({ onClick, label, ...props }) => {
  const { t } = useTranslation();

  return (
    <TopbarActionButton
      onClick={onClick}
      color="red"
      label={label || t('Global.Delete')}
      iconImage={<TrashSolidIcon />}
      additionalIconClasses="ml-0.5 mr-3"
      {...props}
    />
  );
};

export default DeleteButton;

DeleteButton.propTypes = {
  /**
   * On delete click
   */
  onClick: PropTypes.func.isRequired,
  /**
   * Button label, default "Delete"
   */
  label: PropTypes.node,
};

DeleteButton.defaultProps = {};
