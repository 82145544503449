import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { twMerge } from 'tailwind-merge';
import { toast } from 'react-hot-toast';

// :: Components
import Button from '../../components/Button/Button';
import Heading from '../../components/Heading/Heading';
import Loader from '../../components/Loader/Loader';
import ResultsPerPageDropdown from '../../components/Pagination/ResultsPerPageDropdown';
import StatusBar from '../../components/StatusBar/StatusBar';
import CustomizableDataGrid from '../../components/CustomizableDataGrid/CustomizableDataGrid';
import LinkButton from '../../components/LinkButton/LinkButton';
import TopbarButton from '../../components/Topbar/buttons/base/TopbarButton';
import TopbarBreadcrumbs from '../../components/Topbar/breadcrumbs/TopbarBreadcrumbs';
import Tooltip from '../../components/Tooltip/Tooltip';

// :: Hooks
import { useUserRoles } from '../../hooks/api';
import useApiErrorsToast from '../../hooks/api/useApiErrorsToast';
import useToken from '../../hooks/useToken';
import useSelectedSpace from '../../hooks/useSelectedSpace';
import useActionsColumn from '../../components/DataGrid/useActions';
import { useGridFilters } from '../../components/DataGrid/useGridFilters';

// :: Contexts
import { useModals } from '../../contexts/ModalContext';
import UserContext from '../../contexts/UserContext';

// :: Lib
import { getLocalStorage, removeLocalStorage } from '../../utils/localStorage';
import { getTestProps, setInitialGridOptions } from '../../lib/helpers';
import { defaultRenderer } from '../../components/DataGrid/DataGridCell/cellRenderer.js';

// :: Api
import {
  ResponseError,
  checkResponseStatus,
} from '../../lib/flotiq-client/response-errors';
import { deleteUserRole } from '../../lib/flotiq-client';

// :: Layout
import PageLayout from '../../layout/PageLayout/PageLayout';

// :: Images
import {
  RefreshIcon,
  WarningIcon,
  ZoomMaleWorkingImage,
} from '../../images/shapes';

const SORT_KEY = 'cms.user-roles-sort';
const OPTIONS_KEY = 'cms.user-roles-grid-state';

const UserRoles = ({ testId }) => {
  const rolesGridContainer = useRef();
  const { t } = useTranslation();
  const jwt = useToken();
  const modal = useModals();
  const { space, buildUrlWithSpace } = useSelectedSpace();
  const { isAdmin, planLimits } = useContext(UserContext);

  const [gridOptions, setGridOptions] = useState(
    getLocalStorage(OPTIONS_KEY, true),
  );

  const [sort, setSort] = useState(getLocalStorage(SORT_KEY, true));
  const [firstLoading, setFirstLoading] = useState(true);
  const [initDataHasContent, setInitDataHasContent] = useState(false);
  const [columns, setColumns] = useState([]);

  useEffect(() => {
    setSort(getLocalStorage(SORT_KEY, true));
  }, []);

  useEffect(() => {
    setGridOptions(getLocalStorage(OPTIONS_KEY, true));
  }, []);

  const {
    filters,
    filtersApplied,
    handleFiltersChange,
    page,
    handlePageChange,
    limit,
    handleLimitChange,
    removeAllFilters,
  } = useGridFilters('user-roles');

  const rolesParams = useMemo(
    () => ({
      page,
      limit,
      ...(filters.name?.filter ? { name: filters.name.filter } : {}),
      ...(sort?.sortOrder ? { order_direction: sort?.sortOrder } : {}),
      ...(sort?.sortField ? { order_by: sort?.sortField } : {}),
    }),
    [page, limit, filters.name, sort?.sortOrder, sort?.sortField],
  );

  const rolesOptions = useMemo(
    () => ({
      pause: !isAdmin,
    }),
    [isAdmin],
  );

  const {
    data: roles,
    errors: rolesErrors,
    status: rolesStatus,
    isLoading: rolesAreLoading,
    pagination,
    reload,
  } = useUserRoles(rolesParams, rolesOptions);

  useApiErrorsToast(rolesErrors);

  useEffect(() => {
    if (firstLoading) {
      setInitDataHasContent(false);
    }
    if (!rolesAreLoading) setFirstLoading(false);
  }, [firstLoading, rolesAreLoading]);

  useEffect(() => {
    if (roles.length && !initDataHasContent) {
      setInitDataHasContent(true);
    }
  }, [initDataHasContent, roles.length]);

  useEffect(() => {
    if (pagination?.total_pages < page)
      handlePageChange(pagination.total_pages);
  }, [handlePageChange, page, pagination?.total_pages]);

  const handleResetGrid = useCallback(() => {
    setSort();
    removeAllFilters();
    removeLocalStorage(SORT_KEY);
    removeLocalStorage(OPTIONS_KEY);
    setGridOptions();
  }, [removeAllFilters]);

  const showGrid = useMemo(
    () =>
      isAdmin &&
      (roles.length > 0 ||
        (rolesAreLoading && !firstLoading) ||
        filtersApplied ||
        initDataHasContent),
    [
      isAdmin,
      roles.length,
      rolesAreLoading,
      firstLoading,
      filtersApplied,
      initDataHasContent,
    ],
  );

  const emptyData = useMemo(() => {
    if (showGrid) return null;
    if (firstLoading && isAdmin) {
      return (
        <Loader
          size="big"
          type="spinner-grid"
          {...getTestProps(testId, 'loading', 'testId')}
        />
      );
    }
    if (rolesStatus === 403 || !isAdmin || rolesErrors)
      return (
        <Heading
          level={2}
          additionalClasses="text-3xl md:text-4xl leading-8 dark:text-white"
        >
          <div
            className="flex flex-col items-center justify-center text-center"
            {...getTestProps(testId, 'empty-data')}
          >
            <WarningIcon className="text-red w-14 md:w-20 mb-3" />
            {rolesStatus === 403 || !isAdmin
              ? t('Global.NoAccess')
              : t('Media.OnErrorMessage')}
          </div>
        </Heading>
      );
    return (
      <>
        <ZoomMaleWorkingImage className="w-52" />
        <div
          className="text-center font-bold p-5"
          {...getTestProps(testId, 'empty-data')}
        >
          <p className="text-blue-600 text-2xl sm:text-3xl md:text-4xl">
            {t('ContentDefinition.CreateFirst')}
          </p>
          <p className="text-indigo-950 dark:text-white text-3xl sm:text-4xl md:text-5xl">
            {t('UserRoles.CreateUserRole')}
          </p>
        </div>
        <Tooltip
          tooltip={
            !planLimits?.user_roles_enabled ? t('UserRoles.NoCustomRoles') : ''
          }
        >
          <LinkButton
            buttonSize="base"
            link={buildUrlWithSpace('user-roles/add')}
            disabled={!planLimits?.user_roles_enabled}
            {...getTestProps(testId, 'add-first', 'testId')}
          >
            {t('UserRoles.Add')}
          </LinkButton>
        </Tooltip>
      </>
    );
  }, [
    showGrid,
    firstLoading,
    isAdmin,
    rolesStatus,
    rolesErrors,
    testId,
    t,
    buildUrlWithSpace,
    planLimits?.user_roles_enabled,
  ]);

  const handleDeleteRole = useCallback(
    async (id) => {
      modal.deleting('delete-modal');
      try {
        const { body, status } = await deleteUserRole(jwt, space, {
          id,
        });
        checkResponseStatus(body, status);
        toast.success(t('UserRoles.Deleted'));
        reload();
      } catch (error) {
        if (!(error instanceof ResponseError)) {
          toast.error(t('Form.CommunicationErrorMessage'));
        } else {
          toast.error(
            error.message ? error.message : t('UserRoles.DeletingError'),
          );
        }
      }
    },
    [jwt, modal, space, reload, t],
  );

  const showDeleteModal = useCallback(
    async (id) => {
      modal.delete(t('UserRoles.ConfirmDelete'), 'delete-modal', () =>
        handleDeleteRole(id),
      );
    },
    [modal, t, handleDeleteRole],
  );

  const handleInitialGridOptions = useCallback((cols, minWidth) => {
    setInitialGridOptions(
      cols,
      minWidth,
      rolesGridContainer.current.offsetWidth - 50,
      setGridOptions,
      setColumns,
      OPTIONS_KEY,
    );
  }, []);

  useEffect(() => {
    const gridOptionsByID = gridOptions?.reduce((acc, elem) => {
      acc[elem.colId] = elem;
      return acc;
    }, {});

    const cols = [
      {
        accessor: 'name',
        label: t('Global.Name'),
        width: gridOptionsByID?.['name']?.width,
        render: (data) => defaultRenderer(data),
        sortable: true,
        filterInputType: 'text',
        resizable: true,
      },
      {
        accessor: 'description',
        label: t('Global.Description'),
        width: gridOptionsByID?.['description']?.width,
        render: (data) => defaultRenderer(data),
        resizable: true,
      },
    ];

    if (!gridOptions) {
      handleInitialGridOptions(cols, 250);
    } else {
      setColumns(cols);
    }
  }, [t, handleInitialGridOptions, gridOptions, testId]);

  const actionColumn = useActionsColumn(
    'user-roles',
    ['edit'],
    ['edit', 'duplicate', 'delete'],
    '',
    true,
    (rowData) => showDeleteModal(rowData.id),
    testId,
  );

  const allColumns = useMemo(
    () => [actionColumn, ...columns],
    [columns, actionColumn],
  );

  return (
    <PageLayout
      page="userRoles"
      id="user-roles"
      title={t('Global.UserRoles')}
      breadcrumbs={<TopbarBreadcrumbs />}
      buttons={
        isAdmin ? (
          <TopbarButton
            label={t('UserRoles.Add')}
            link={buildUrlWithSpace('user-roles/add')}
            {...(!planLimits?.user_roles_enabled
              ? {
                  disabled: true,
                  tooltip: t('UserRoles.NoCustomRoles'),
                  tooltipPlacement: 'leftBottom',
                }
              : {})}
          />
        ) : null
      }
      testId={testId}
    >
      <div className="w-full md:h-[calc(100vh-66px)]">
        <div className="flex flex-col h-full w-full">
          <div
            className={twMerge(
              'flex flex-wrap sm:flex-nowrap items-center justify-end ',
              'px-3 lg:px-7 py-2 h-auto sm:h-11 w-full z-10 bg-slate-50 border-b gap-3 sm:gap-0',
              'dark:bg-gray-900 dark:border-b dark:border-slate-800',
            )}
          >
            {showGrid && (
              <div className="flex items-center justify-end w-full xs:w-auto">
                <Button
                  buttonSize="sm"
                  iconImage={<RefreshIcon className="text-blue w-4" />}
                  onClick={handleResetGrid}
                  buttonColor={'borderless'}
                  additionalClasses="text-sm sm:text-base"
                  noPaddings
                  {...getTestProps(testId, 'reset-grid', 'testId')}
                >
                  {t('Global.ResetGrid')}
                </Button>
                <ResultsPerPageDropdown
                  value={limit}
                  setResultsPerPage={handleLimitChange}
                  additionalButtonClasses="!pr-8 2xl:!py-1"
                  additionalClasses="!p-0 ml-5 text-sm sm:text-base"
                  label={t('Global.ResultsPerPage')}
                  {...getTestProps(testId, 'results-per-page', 'testId')}
                />
              </div>
            )}
          </div>
          <div className="rounded-lg mt-7 px-5 lg:px-7 w-full h-full">
            <div
              className="w-full h-[calc(100vh-230px)] md:h-[calc(100%-150px)]"
              ref={rolesGridContainer}
            >
              {showGrid ? (
                <CustomizableDataGrid
                  setSort={setSort}
                  sortingLocalStorageKey={SORT_KEY}
                  optionsLocalStorageKey={OPTIONS_KEY}
                  setCurrentPage={handlePageChange}
                  gridOptions={gridOptions}
                  setGridOptions={setGridOptions}
                  columns={allColumns}
                  data={roles}
                  isLoading={rolesAreLoading}
                  sort={sort?.sortField || undefined}
                  sortOrder={sort?.sortOrder || undefined}
                  statusBar={
                    <StatusBar
                      rows={pagination.count}
                      currentPage={page}
                      pagesCount={pagination.total_pages}
                      handlePageChange={handlePageChange}
                      handleDataUpdate={reload}
                      resultsFrom={(pagination.current_page - 1) * limit + 1}
                      resultsTo={
                        (pagination.current_page - 1) * limit + pagination.count
                      }
                      resultsTotalCount={pagination.total_count}
                      {...getTestProps(testId, 'statusbar', 'testId')}
                    />
                  }
                  noDataInfoText={t('UserRoles.FiltersEmptyResult')}
                  hasFilters
                  filters={filters}
                  onFilter={handleFiltersChange}
                  additionalClasses={twMerge('bg-white dark:bg-slate-950 ')}
                  {...getTestProps(testId, 'grid', 'testId')}
                />
              ) : (
                <div className="flex flex-col items-center justify-center h-full">
                  {emptyData}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </PageLayout>
  );
};

export default UserRoles;

UserRoles.propTypes = {
  /**
   * Test id for user roles page
   */
  testId: PropTypes.string,
};

UserRoles.defaultProps = {
  testId: '',
};
