import PropTypes from 'prop-types';
import { twMerge } from 'tailwind-merge';
import Topbar from '../../components/Topbar/Topbar';
import { FlotiqBadgeLogo } from '../../images/shapes';
import { Helmet } from 'react-helmet';
import { useContext, useEffect } from 'react';
import AppContext from '../../contexts/AppContext';
import { getTestProps } from '../../lib/helpers';

const PageLayout = ({
  title,
  breadcrumbs,
  buttons,
  buttonsDisabled,
  children,
  id,
  page,
  menuItemOpen,
  testId,
}) => {
  const { updateAppContext } = useContext(AppContext);

  useEffect(() => {
    updateAppContext?.((prevState) => ({
      ...prevState,
      id: id || page,
      ...(page ? { page } : {}),
      ...(menuItemOpen ? { menuItemOpen } : {}),
    }));
  }, [id, menuItemOpen, page, updateAppContext]);

  return (
    <div className="relative h-full w-full">
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <Topbar
        topbarLogo={<FlotiqBadgeLogo className="h-10" />}
        topbarHeading={title || ''}
        buttons={buttons}
        buttonsDisabled={buttonsDisabled}
        additionalClasses={twMerge(
          'sticky top-0 z-[19] w-full',
          'transition-all',
          'ease-in-out',
          'duration-normal',
        )}
        breadcrumbs={breadcrumbs}
        {...getTestProps(testId, 'topbar', 'testId')}
      />
      <div className="flex items-stretch w-full min-h-[calc(100vh-71px)]">
        {children}
      </div>
    </div>
  );
};

export default PageLayout;

PageLayout.propTypes = {
  /**
   * Page title shown in helmet and topbar
   */
  title: PropTypes.string.isRequired,
  /**
   * Topbar breadcrumbs
   */
  breadcrumbs: PropTypes.node,
  /**
   * Topbar buttons
   */
  buttons: PropTypes.node,
  /**
   * If topbar buttons are disabled
   */
  buttonsDisabled: PropTypes.bool,
  /**
   * Page id
   */
  id: PropTypes.string,
  /**
   * Page sidebar key
   */
  page: PropTypes.string,
  /**
   * Sidebar menu items that should be opened
   */
  menuItemOpen: PropTypes.string,
  /**
   * Component test id
   */
  testId: PropTypes.string,
};

PageLayout.defaultProps = {
  buttonsDisabled: false,
  testId: '',
};
