import { useMemo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { twMerge } from 'tailwind-merge';
import { useTranslation } from 'react-i18next';
import { getFormatedSize, getTestProps } from '../../../lib/helpers';
import TypeCardRow from './TypeCardRow';
import StatusPill from '../../../components/StatusPill/StatusPill';

const panelColor = {
  lime: 'lime',
  blue: 'blue',
  green: 'green',
  orange: 'orange',
  disabled: 'gray',
};

const SpaceTypeCard = ({
  name,
  price,
  color,
  selected,
  plan,
  handleSelected,
  additionalClasses,
  disabled,
  limit,
  isCurrentPlan,
  testId,
  isExpanded,
}) => {
  const { t } = useTranslation();
  const [renderedItems, setRenderedItems] = useState([]);

  const planDetails = useMemo(() => {
    const bodyDetails = [
      {
        id: `monthly-calls-${plan.name}`,
        value: getFormatedSize(plan.apiCallsLimit, 'compact-number', 'Custom'),
        title: t('Spaces.APICallsMo'),
      },
      {
        id: `objects-limits-${plan.name}`,
        value: getFormatedSize(plan.ctoLimit, 'compact-number', 'Custom'),
        title: t('Spaces.COLimit'),
      },
      {
        id: `object-types-${plan.name}`,
        value: getFormatedSize(plan.ctdLimit, '', 'Custom'),
        title: t('Spaces.CTDLimit'),
      },
      {
        id: `file-quota-${plan.name}`,
        value: getFormatedSize(plan.fileQuota, 'MB', 'Custom'),
        title: t('Spaces.FileQuota'),
      },
      {
        id: `team-members-${plan.name}`,
        value: getFormatedSize(plan.teamMembersLimit, '', 'Custom'),
        title: t('Spaces.TeamMembers'),
      },
      {
        id: `webhooks-${plan.name}`,
        value: getFormatedSize(plan.webhooksLimit, '', 'Custom'),
        title: t('Spaces.Webhooks'),
      },
      {
        id: `scoped-api-keys-${plan.name}`,
        value: getFormatedSize(plan.scopedKeysLimit, '', 'Custom'),
        title: t('Spaces.ScopedApiKey'),
      },
      {
        id: `support-${plan.name}`,
        value: getFormatedSize(plan.support, '', 'Custom'),
        title: plan.support && t('Spaces.Support'),
      },
    ];
    return { body: bodyDetails };
  }, [plan, t]);

  useEffect(() => {
    if (isExpanded) {
      setRenderedItems(planDetails.body);
    } else {
      setRenderedItems(planDetails.body.slice(0, 4));
    }
  }, [isExpanded, planDetails.body]);

  const handleCardClick = (event) => {
    if (!event.target.closest('button') && !disabled) {
      event.stopPropagation();
      handleSelected(plan);
    }
  };

  return (
    <div
      className={twMerge(
        'rounded-lg bg-transparent cursor-pointer relative group',
        disabled &&
          'bg-gray-200 hover:bg-gradient-transparent cursor-not-allowed',
        additionalClasses,
      )}
      onClick={handleCardClick}
      {...getTestProps(testId, 'container')}
    >
      {isCurrentPlan && (
        <StatusPill
          status={t('PlanHistory.CurrentPlan')}
          color={color === 'orange' ? 'orange' : 'blue'}
          containerClasses={twMerge(
            'absolute left-1/2 border-0 dark:border-0 -translate-x-1/2 -top-2.5 pt-1.5 pb-1 px-3 h-auto',
            'uppercase text-sm text-white dark:text-white rounded-lg whitespace-nowrap font-semibold z-10',
            `bg-${panelColor[color]} dark:bg-${panelColor[color]}`,
          )}
        />
      )}
      <div
        className={twMerge(
          'absolute w-[calc(100%+4px)] h-[calc(100%+4px)] m-[-2px] rounded-lg bg-transparent',
          selected && `border-${panelColor[color]}`,
          selected && 'border-4',
          disabled && 'group-hover:bg-gradient-transparent',
        )}
        {...getTestProps(testId, 'border-box')}
      />
      <div
        className={twMerge(
          'relative w-full rounded-lg overflow-hidden bg-white dark:bg-gray-900 dark:text-white h-full border',
          'border-slate-200 dark:border-gray-800 pb-1.5 group-hover:shadow-[0px_4px_20px_0px_#00000026]',
          selected &&
            `border-${panelColor[color]} dark:border-${panelColor[color]} text-${panelColor[color]}`,
          disabled && 'bg-[#f4f4f4]',
        )}
      >
        {/* Section: head */}
        <div
          className={twMerge(
            'flex flex-wrap items-center px-4 py-3 gap-2 border-t-[15px] relative',
            `border-${panelColor[disabled ? 'disabled' : color]}`,
            `text-${panelColor[disabled ? 'disabled' : color]}`,
          )}
          {...getTestProps(testId, 'panel')}
        >
          <span
            className={twMerge(
              'flex items-center font-bold text-2xl gap-2 uppercase',
              `text-${panelColor[color]}`,
            )}
            {...getTestProps(testId, 'name')}
          >
            {name}
            {limit && (
              <span
                className="text-indigo-950 dark:text-white text-base"
                {...getTestProps(testId, 'limit')}
              >
                {limit}
              </span>
            )}
          </span>
          <span
            className="font-bold text-lg ml-auto text-indigo-950 dark:text-white"
            {...getTestProps(testId, 'price')}
          >
            {price}
          </span>
        </div>
        {/* Section: sub */}
        <div className="p-4">
          <div
            className="pb-3 font-bold text-indigo-950 dark:text-white"
            {...getTestProps(testId, 'sub')}
          >
            {t('Spaces.PlanIncludes')}:
          </div>
          {/* Section: body */}
          <div
            style={{
              maxHeight: isExpanded
                ? `${renderedItems.length * 60}px`
                : '240px',
            }}
            className="transition-all duration-300 ease-in-out overflow-hidden"
          >
            {renderedItems.map((el) => (
              <TypeCardRow
                key={el.id}
                title={el.title}
                value={el.value}
                color={color}
                additionalValueClass={el.valueClass}
                {...getTestProps(testId, `body-row-${el.id}`, 'testId')}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

SpaceTypeCard.propTypes = {
  /**
   *  Space name
   */
  name: PropTypes.string,
  /**
   *  Space price
   */
  price: PropTypes.string,
  /**
   *  If card is selected
   */
  selected: PropTypes.bool,
  /**
   *  Plan object
   */
  plan: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    spaceLicense: PropTypes.number,
    bandwidth: PropTypes.number,
    teamMembersLimit: PropTypes.number,
    scopedKeysLimit: PropTypes.number,
    apiCallsLimit: PropTypes.number,
    ctoLimit: PropTypes.number,
    ctdLimit: PropTypes.number,
    fileQuota: PropTypes.number,
  }),
  /**
   *  On select handler
   */
  handleSelected: PropTypes.func,
  /**
   *  Card color
   */
  color: PropTypes.string,
  /**
   *  If the plan is current space plan
   */
  isCurrentPlan: PropTypes.bool,
  /**
   *  Additional classes
   */
  additionalClasses: PropTypes.string,
  /**
   *  Test Id
   */
  testId: PropTypes.string,
};

SpaceTypeCard.defaultProps = {
  name: '',
  price: '',
  selected: false,
  plan: {},
  handleSelected: undefined,
  color: 'gray',
  additionalClasses: '',
  testId: '',
  limit: '',
  isCurrentPlan: false,
};

export default SpaceTypeCard;
