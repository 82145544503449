import { useState, useRef, useContext, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Trans, useTranslation } from 'react-i18next';
import { toast } from 'react-hot-toast';
import { useParams } from 'react-router-dom';
import { useFormik, getIn, FormikContext } from 'formik';
import * as yup from 'yup';
import { twMerge } from 'tailwind-merge';
import moment from 'moment';

// :: Components
import Button from '../../components/Button/Button';
import ContentObjectInformations from '../../components/ContentObjectInformations/ContentObjectInformations';
import ContentObjectVersions from '../../components/ContentObjectVersions/ContentObjectVersions';
import ContentObjectLogs from '../../components/ContentObjectLogs/ContentObjectLogs';
import Dropdown from '../../components/Dropdown/Dropdown';
import Input from '../../components/Input/Input';
import Loader from '../../components/Loader/Loader';
import Switch from '../../components/Switch/Switch';
import ElementFromPlugin from '../../components/ElementFromPlugin/ElementFromPlugin';
import CustomFormElement from '../../form/ContentObjectForm/CustomFormElement/CustomFormElement';
import CodeEditor from '../../components/CodeEditor/CodeEditor';
import RadioGroup from '../../components/RadioGroup/RadioGroup';
import Tooltip from '../../components/Tooltip/Tooltip';
import TopbarBreadcrumbs from '../../components/Topbar/breadcrumbs/TopbarBreadcrumbs';
import CancelButton from '../../components/Topbar/buttons/CancelButton';
import SaveButton from '../../components/Topbar/buttons/SaveButton';
import TopbarActionMenu from '../../components/Topbar/buttons/base/TopbarActionMenu';
import DeleteButton from '../../components/Topbar/buttons/DeleteButton';

// :: Components Sections
import SectionActions from './Sections/Actions';
import SectionHeaders from './Sections/Headers';

// :: Context
import { useModals } from '../../contexts/ModalContext';
import UserContext from '../../contexts/UserContext';
import ContentObjectFormContext from '../../contexts/ContentObjectFormContext';

// :: Lib plugins
import { FormAddSidebarPanelEvent } from '../../lib/flotiq-plugins/plugin-events/FormAddSidebarPanelEvent';

// :: Lib client
import {
  getWebhooks,
  postWebhooks,
  putWebhooks,
  deleteWebhooks,
  versionWebhooks,
  getContentObjectVersion,
  logsWebhooks,
  listContentTypes,
} from '../../lib/flotiq-client';
import {
  ResponseError,
  checkResponseStatus,
} from '../../lib/flotiq-client/response-errors';

// :: Lib helpers
import { getTestProps, isModuleEnabled } from '../../lib/helpers';

// :: Layout
import PageLayout from '../../layout/PageLayout/PageLayout';

// :: Hooks
import useToken from '../../hooks/useToken';
import useOnce from '../../hooks/useOnce';
import {
  useConstraints,
  useContentType,
  useContentTypes,
  usePluginsSettings,
} from '../../hooks/api';
import useApiErrorsToast from '../../hooks/api/useApiErrorsToast';
import useSelectedSpace from '../../hooks/useSelectedSpace';
import usePluginResults from '../../hooks/usePluginResults';
import { useGridNavigate } from '../../components/DataGrid/useGridFilters';

// :: Const
const DEFAULT_CODE_EDITOR =
  'function _webhook(ctx) {\n' +
  '        const { payload } = ctx;\n' +
  '        const newPayload = { ...payload };\n\n' +
  '        // Example: Payload validation\n' +
  "        // if ( payload['category'] === 'book' && !payload['isbn'])\n" +
  "        // return { errors: {'isbn': ['Error: ISBN is required for books.'] } };\n\n" +
  '        // Example: Payload modifications\n' +
  "        // newPayload['slug'] = payload['title']\n" +
  '        //     .toLowerCase()\n' +
  "        //     .replace(/[^a-z0-9]+/g, '-')\n" +
  "        //     .replace(/(^-|-$)/g, '');\n\n" +
  '        return { payload: newPayload };\n' +
  '}';

const USER_PLUGINS_PARAMS = {
  limit: 1000,
  page: 1,
};

const AddWebhooks = ({ mode, duplicate, testId }) => {
  const { t } = useTranslation();
  const { space, buildUrlWithSpace } = useSelectedSpace();
  const { permissions, planLimits } = useContext(UserContext);

  const { navigateGrid, gridLink } = useGridNavigate(
    `objects-_webhooks`,
    buildUrlWithSpace(`webhooks`),
  );

  const { data: userPlugins } = usePluginsSettings(USER_PLUGINS_PARAMS);

  const { entity: webhookContentType } = useContentType('_webhooks');

  const { canCreate, canDelete, canUpdate } = useMemo(
    () => permissions.getCoPermissions('_webhooks') || {},
    [permissions],
  );

  // :: Limits
  const codeWebhookLimit = planLimits?.hosted_webhooks_limit;

  const { entity: hostedWebhookCount, reload: reloadHostedWebhookCount } =
    useConstraints('hosted-webhooks-count');

  const codeWebhookAvaialable = useMemo(
    () =>
      codeWebhookLimit !== -1
        ? hostedWebhookCount?.data < codeWebhookLimit
        : true,
    [codeWebhookLimit, hostedWebhookCount],
  );

  const jwt = useToken();
  let { id } = useParams();
  const modal = useModals();
  const refSubmitButton = useRef();

  const [webhookVersions, setWebhookVersions] = useState([]);
  const [webhookLogs, setWebhookLogs] = useState([]);
  const [webhookLogsLoading, setWebhookLogsLoading] = useState(false);
  const [webhookLoading, setWebhookLoading] = useState(false);
  const [webhook, setWebhook] = useState();

  const [isLoading, setIsLoading] = useState(false);
  const [validateAfterSubmit, setValidateAfterSubmit] = useState(false);

  const validationSchema = yup.object({
    name: yup.string().min(1).required('Must be at least 1 characters long'),
    url: yup.string().when('executionMode', {
      is: 'url',
      then: yup.string().min(1).required('Must be at least 1 characters long'),
    }),
    code: yup.string().when('executionMode', {
      is: 'code',
      then: yup.string().min(1).required('Must be at least 1 characters long'),
    }),
    type: yup.string().required(t('Form.FormErrorNotBlank')),
    contentTypeDefinitions: yup.array().when('type', {
      is: 'ctd',
      then: yup.array().min(0),
      otherwise: yup.array(),
    }),
    actions: yup.array().when('type', {
      is: 'ctd',
      then: yup.array().min(0),
      otherwise: yup
        .array()
        .min(1, t('PropertyForm.Errors.OptionsLength'))
        .required(t('PropertyForm.Errors.OptionsLength')),
    }),
    headers: yup.array().of(
      yup.object().shape({
        header_name: yup
          .string()
          .min(1)
          .required('Must be at least 1 characters long'),
        header_value: yup
          .string()
          .min(1)
          .required('Must be at least 1 characters long'),
      }),
    ),
  });

  const formik = useFormik({
    initialValues: {
      name: '',
      url: '',
      contentTypeDefinitions: [],
      actions: [],
      headers: [],
      enabled: false,
      type: 'async',
      executionMode: 'url',
      code: DEFAULT_CODE_EDITOR,
    },
    validateOnChange: validateAfterSubmit,
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: async (values) => {
      setIsLoading(true);
      const errors = await handleSave(values);
      formik.setStatus({ ...formik.status, errors });
      setIsLoading(false);
      reloadHostedWebhookCount();
    },
  });

  const disableSave =
    !codeWebhookAvaialable && formik?.values?.executionMode === 'code';

  const handleSubmit = useCallback(
    (event) => {
      event.preventDefault();
      setValidateAfterSubmit(true);
      formik.handleSubmit();
      setValidateAfterSubmit(false);
    },
    [formik],
  );

  const handleDeleteWebhook = useCallback(async () => {
    const result = await modal.confirmation(
      t('Webhooks.DeleteModalText'),
      t('Global.Warning'),
      t('Global.Delete'),
      t('Global.Cancel'),
      'warning',
      { size: 'lg' },
    );

    if (result) {
      try {
        const { body, status } = await deleteWebhooks(jwt, space, { id });
        checkResponseStatus(body, status);

        navigateGrid();
      } catch (error) {
        if (!(error instanceof ResponseError)) {
          toast.error(t('Webhooks.Error'));
        } else {
          toast.error(error.message);
        }
      }
    }
  }, [modal, t, jwt, space, id, navigateGrid]);

  const handleUpdateFormik = useCallback(
    (body, id) => {
      formik.setValues({
        ...body,
        actions: body?.actions?.map((el) => el.action),
        contentTypeDefinitions: body?.content_type_definitions?.map(
          (el) => el.content_type_definition_name,
        ),
        headers: body?.headers
          ? body?.headers?.map((el, index) => ({
              ...el,
              id: `#hid-${id}-${index}`,
            }))
          : [],
      });
    },
    // eslint-disable-next-line
    [],
  );

  const handleFetchWebhook = useCallback(async () => {
    if (mode === 'edit') {
      setWebhookLoading(true);
      try {
        const { body, status } = await getWebhooks(jwt, space, { id });

        checkResponseStatus(body, status);
        setWebhook(body);

        handleUpdateFormik(body, id);
      } catch (error) {
        if (!(error instanceof ResponseError)) {
          toast.error(t('Webhooks.FetchError'));
        } else {
          toast.error(error.message);
        }
        navigateGrid();
      }
      setWebhookLoading(false);
    }
  }, [mode, jwt, space, id, handleUpdateFormik, navigateGrid, t]);

  useOnce(handleFetchWebhook);

  const handleFetchVersion = useCallback(async () => {
    if (mode === 'edit' && !duplicate) {
      try {
        const { body, status } = await versionWebhooks(jwt, space, {
          id,
          limit: 1000,
        });
        checkResponseStatus(body, status);

        const sortedByVersion = body.data.sort((a, b) => {
          if (a.version > b.version) {
            return -1;
          }
          if (a.version < b.version) {
            return 1;
          }
          return 0;
        });

        setWebhookVersions(sortedByVersion);
      } catch (error) {
        if (!(error instanceof ResponseError)) {
          toast.error(t('Webhooks.Error'));
        } else {
          toast.error(error.message);
        }
      }
    }
  }, [mode, space, duplicate, jwt, t, id]);

  useOnce(handleFetchVersion);

  const handleFetchLogs = useCallback(async () => {
    if (mode === 'edit' && !duplicate) {
      setWebhookLogsLoading(true);

      try {
        const { body, status } = await logsWebhooks(jwt, space, { id });

        checkResponseStatus(body, status);

        setWebhookLogs(body.data);
      } catch (error) {
        if (!(error instanceof ResponseError)) {
          toast.error(t('Webhooks.Error'));
        } else {
          toast.error(error.message);
        }
      }

      setWebhookLogsLoading(false);
    }
  }, [mode, space, duplicate, jwt, t, id]);

  useOnce(handleFetchLogs);
  const isWebhookTypeCtd = useMemo(
    () => formik?.values?.type === 'ctd',
    [formik],
  );

  const validateCodeEditorValue = useCallback((code, type, mode) => {
    // Case: code value should be empty if
    // - default editor code was not changed by the user and
    //   execution mode is switch to "url"
    // - when selected type is not 'sync'
    if (type !== 'sync' || (mode === 'url' && code === DEFAULT_CODE_EDITOR)) {
      return '';
    }

    return code;
  }, []);

  const handleSave = useCallback(
    async (values) => {
      let updateShape = {
        name: values?.name,
        url: values?.url,
        enabled: values?.enabled,
        type: values?.type,
        executionMode: values?.executionMode || 'url',
        code: validateCodeEditorValue(
          values?.code,
          values?.type,
          values?.executionMode,
        ),
        headers: values?.headers?.map((el) => {
          const { id, ...rest } = el;
          return {
            ...rest,
          };
        }),
        ...(!isWebhookTypeCtd
          ? {
              actions: values?.actions?.map((action) => {
                return { action };
              }),
              content_type_definitions: values?.contentTypeDefinitions?.map(
                (content_type_definition_name) => {
                  return { content_type_definition_name };
                },
              ),
            }
          : { actions: [], content_type_definitions: {} }),
      };
      if (mode === 'edit' && !duplicate) {
        try {
          const { body, status } = await putWebhooks(jwt, space, {
            ...updateShape,
            id,
          });
          checkResponseStatus(body, status);

          toast.success(`${t('Webhooks.SavedOnSucces')}`);
          navigateGrid();
        } catch (error) {
          if (!(error instanceof ResponseError)) {
            toast.error(t('Webhooks.SavedOnError'));
          } else {
            toast.error(error.message);
          }
        }
      } else {
        try {
          const { body, status } = await postWebhooks(jwt, space, updateShape);
          checkResponseStatus(body, status);

          toast.success(`${t('Webhooks.AddedOnSucces')}`);
          navigateGrid();
        } catch (error) {
          if (!(error instanceof ResponseError)) {
            toast.error(t('Webhooks.SavedOnError'));
          } else {
            toast.error(error.message);
          }
        }
      }
    },
    [
      validateCodeEditorValue,
      isWebhookTypeCtd,
      mode,
      duplicate,
      jwt,
      space,
      id,
      t,
      navigateGrid,
    ],
  );

  const handleChangeActions = useCallback(
    (e) => {
      if (e.target.value) {
        formik.setFieldValue('actions', [
          ...formik.values.actions,
          e.target.name,
        ]);
      } else {
        formik.setFieldValue(
          'actions',
          formik.values.actions.filter((el) => el !== e.target.name),
        );
      }
    },
    [formik],
  );

  // :: Content Type Data
  const ctdParams = useMemo(
    () => ({
      limit: 1000,
      order_by: 'label',
      order_direction: 'asc',
      page: 1,
    }),
    [],
  );

  const {
    data: contentTypes,
    isLoading: isLoadingCT,
    errors: ctdErrors,
  } = useContentTypes(ctdParams);

  useApiErrorsToast(ctdErrors);

  const contentTypesOptions = useMemo(() => {
    if (!contentTypes) return;

    return contentTypes
      .filter(
        (el) => !(el.internal && el.name !== '_tag' && el.name !== '_media'),
      )
      .map((el) => {
        return {
          value: el.name,
          label: el.label,
        };
      });
  }, [contentTypes]);

  const typeOptions = useMemo(() => {
    return [
      {
        value: 'async',
        label: t('Webhooks.TypeOptions.Asynchronous'),
      },
      isModuleEnabled('WEBHOOKS_TYPE') && {
        value: 'sync',
        label: t('Webhooks.TypeOptions.Synchronous'),
      },
      {
        value: 'ctd',
        label: t('Webhooks.TypeOptions.Ctd'),
      },
    ].filter(Boolean);
  }, [t]);

  const filterCtdName = useCallback(
    async (query, _, setIsLoading) => {
      setIsLoading(true);
      let newOptions = [];
      try {
        const { body, status } = await listContentTypes(jwt, space, {
          ...ctdParams,
          name: query,
        });
        checkResponseStatus(body, status);
        newOptions = body.data || [];
      } catch (error) {
        toast.error(
          t(
            error instanceof ResponseError
              ? 'ContentForm.CouldntFetch'
              : 'Form.CommunicationErrorMessage',
          ),
        );
      }

      setIsLoading(false);
      return newOptions
        .filter((ctd) => !ctd.internal || ctd.name === '_media')
        .map((ctd) => ({ label: ctd.label, value: ctd.name }));
    },
    [jwt, ctdParams, space, t],
  );

  const handleAddHeaders = useCallback(() => {
    formik.setFieldValue('headers', [
      ...formik.values.headers,
      {
        header_name: '',
        header_value: '',
        id: `#hid-${new Date().getTime()}`,
      },
    ]);
  }, [formik]);

  const handleRemoveHeaders = useCallback(
    (id) => {
      formik.setFieldValue('headers', [
        ...formik.values.headers.filter((el) => el.id !== id),
      ]);
    },
    [formik],
  );

  const handleVersionSelectCallback = useCallback(
    async (versionNumber) => {
      setWebhookLoading(true);

      try {
        const { body, status } = await getContentObjectVersion(jwt, space, {
          contentTypeName: '_webhooks',
          id: id,
          versionNumber: versionNumber,
        });

        checkResponseStatus(body, status);

        handleUpdateFormik(body, id);

        toast.success(
          t('Webhooks.VersionPreview', {
            date: moment(body?.internal?.updatedAt).format('L LT'),
          }),
        );
      } catch (error) {
        if (!(error instanceof ResponseError)) {
          toast.error(t('Webhooks.GetVersionError'));
        } else {
          toast.error(error.message);
        }
      }

      setWebhookLoading(false);
    },
    [id, handleUpdateFormik, jwt, t, space],
  );

  const isFormDisabled = useMemo(() => {
    if (isLoading) return true;
    if (id) return !canUpdate;
    return !canCreate;
  }, [canCreate, canUpdate, id, isLoading]);

  const WebhookActionsFields = useMemo(() => {
    if (!isWebhookTypeCtd) {
      return (
        <>
          <SectionActions
            actions={formik?.values?.actions}
            onChange={handleChangeActions}
            errors={formik?.errors?.actions}
            disabled={isFormDisabled}
            {...getTestProps(testId, 'actions', 'testId')}
          />

          <label className="block text-sm text-slate-400 mb-2">
            {t('Webhooks.ContentTypeDefinitions')}
          </label>

          {isLoadingCT ? (
            <div className="h-12 flex justify-center items-center">
              <Loader type="spinner-grid" size="small" />
            </div>
          ) : (
            <Dropdown
              name="contentTypeDefinitions"
              options={contentTypesOptions}
              value={formik.values.contentTypeDefinitions}
              multiple
              onChange={formik.handleChange}
              filterCallback={filterCtdName}
              debounceTime={150}
              nullable
              placeholder={t('Webhooks.All')}
              placeholderMultiple={t('Webhooks.ContentTypeDefinitions')}
              error={
                formik.errors.contentTypeDefinitions ||
                formik.status?.errors?.contentTypeDefinitions
              }
              disabled={isFormDisabled}
              {...getTestProps(testId, `dropdown`, 'testId')}
            />
          )}
        </>
      );
    }
  }, [
    contentTypesOptions,
    filterCtdName,
    formik.errors?.actions,
    formik.errors.contentTypeDefinitions,
    formik.handleChange,
    formik.status?.errors?.contentTypeDefinitions,
    formik.values?.actions,
    formik.values.contentTypeDefinitions,
    handleChangeActions,
    isFormDisabled,
    isLoadingCT,
    isWebhookTypeCtd,
    t,
    testId,
  ]);

  const contentObjectFormContextValue = useMemo(
    () => ({
      contentType: webhookContentType,
      initialData: webhook,
      userPlugins,
    }),
    [userPlugins, webhook, webhookContentType],
  );

  /**
   * @emits FlotiqPlugins."flotiq.form.sidebar-panel::add"
   */
  const pluginSidebarPanels = usePluginResults(
    'flotiq.form.sidebar-panel::add',
    FormAddSidebarPanelEvent,
    {
      contentType: webhookContentType,
      contentObject: webhook,
      disabled: isLoading,
      duplicate,
      create: !id,
      userPlugins,
      formik,
    },
  );

  const handleWebhookTypeChange = useCallback(
    (e) => {
      formik.handleChange(e);

      // Case: on change webhook type reset execution mode to "url"
      if (e.target.value !== 'sync') {
        formik.setFieldValue('executionMode', 'url');
      }
    },
    [formik],
  );

  const handleCodeChange = useCallback(
    (value) => {
      formik.setFieldValue('code', value);
    },
    [formik],
  );

  const handleExecuteModeChange = useCallback(
    (e) => {
      formik.handleChange(e);

      // Case: on switch from 'url' to 'code'
      // when 'code' value was empty f.e: user change it before
      // bring back default code value
      if (
        e.target.value === 'code' &&
        (formik.values.code === '' || !formik.values.code)
      ) {
        formik.setFieldValue('code', DEFAULT_CODE_EDITOR);
      }
    },
    [formik],
  );

  const monacoEditorCaption = useMemo(() => {
    if (codeWebhookAvaialable) {
      return (
        <Trans i18nKey="Webhooks.CodeEditorCaption">
          Use code editor to handle sync webhook. Read more, examples in
          <a
            className="underline text-sm text-slate-400"
            href={process.env.REACT_APP_DOCUMENTATION_WEBHOOKS_CODE}
            target="_blank"
            rel="noreferrer"
            {...getTestProps(testId, 'code-editor-docs-link')}
          >
            docs
          </a>
        </Trans>
      );
    }
    return (
      <Trans i18nKey="Webhooks.Code.DisabledCodeEditorCaption">
        Hosted webhooks limit exceeded, click this
        <a
          className="underline text-sm text-slate-400"
          href={process.env.REACT_APP_PRICING}
          target="_blank"
          rel="noreferrer"
          {...getTestProps(testId, 'code-editor-pricing-link')}
        >
          link
        </a>
        to upgrade
      </Trans>
    );
  }, [codeWebhookAvaialable, testId]);

  const executionModeCodeLabel = useMemo(() => {
    if (codeWebhookAvaialable) {
      return <>Code</>;
    }
    return (
      <Tooltip
        tooltip={t('Webhooks.Code.LimitsHostedWebhook')}
        {...getTestProps(testId, 'execution-mode-code', 'testId')}
      >
        Code
      </Tooltip>
    );
  }, [codeWebhookAvaialable, t, testId]);

  const isSaveButton = (canUpdate && id) || (canCreate && !id);

  return (
    <PageLayout
      page="webhooks"
      title={`${mode === 'add' ? t('Global.Add') : t('Global.Edit')} Webhook`}
      breadcrumbs={
        <TopbarBreadcrumbs
          parentTitle={t('Global.Webhooks')}
          parentLink={gridLink}
        />
      }
      buttons={
        <>
          <CancelButton link={gridLink} />
          {isSaveButton && (
            <SaveButton
              form="webhook-form"
              isLoading={isLoading}
              disabled={disableSave}
            />
          )}
          <TopbarActionMenu>
            {mode === 'edit' && !duplicate && canDelete && (
              <DeleteButton onClick={handleDeleteWebhook} />
            )}
          </TopbarActionMenu>
        </>
      }
      testId={testId}
    >
      <div className="flex flex-col w-full">
        <div className="grid grid-cols-1 lg:grid-cols-3 xl:grid-cols-4 h-full mt-7">
          <div
            className="md:col-span-3 bg-white dark:bg-slate-950 rounded-lg mx-4 xl:ml-7 xl:mr-3.5 mb-7 relative"
            {...getTestProps(testId, 'container')}
          >
            {webhookLoading && (
              <div
                className={twMerge(
                  'absolute top-0 left-0 w-full h-full flex bg-white dark:bg-gray-800',
                  'justify-center items-center z-10 rounded-md opacity-90',
                )}
                {...getTestProps(testId, 'container-loader')}
              >
                <Loader type="spinner-grid" size="small" />
              </div>
            )}

            <FormikContext.Provider value={formik}>
              <ContentObjectFormContext.Provider
                value={contentObjectFormContextValue}
              >
                <form
                  id="webhook-form"
                  className="flex flex-col w-full max-w-3xl px-5 m-auto pt-10"
                  onSubmit={handleSubmit}
                  noValidate={true}
                >
                  <CustomFormElement />
                  <Input
                    name="name"
                    type="text"
                    label={`${t('Global.Name')}`}
                    value={formik?.values?.name}
                    onChange={formik.handleChange}
                    error={formik?.errors?.name || formik.status?.errors?.name}
                    additionalClasses={'mb-4'}
                    disabled={isFormDisabled}
                    required
                    {...getTestProps(testId, 'input-name', 'testId')}
                  />

                  <Dropdown
                    name="type"
                    label={t('Webhooks.Type')}
                    options={typeOptions}
                    value={formik?.values?.type || 'async'}
                    onChange={handleWebhookTypeChange}
                    debounceTime={150}
                    placeholder={t('Webhooks.Type')}
                    additionalClasses={'mb-4'}
                    disabled={isFormDisabled}
                    required
                    error={
                      formik.errors.type ||
                      formik.status?.errors?.type ||
                      (formik?.values?.type &&
                      !typeOptions.find(
                        (el) => el.value === formik?.values?.type,
                      )
                        ? t('Webhooks.Errors.SelectedNoLongerValid')
                        : null)
                    }
                    {...getTestProps(testId, `dropdown-type`, 'testId')}
                  />

                  {formik?.values?.type === 'sync' && (
                    <RadioGroup
                      name="executionMode"
                      value={formik?.values?.executionMode || 'url'}
                      options={[
                        {
                          value: 'url',
                          label: 'URL',
                        },
                        {
                          value: 'code',
                          label: executionModeCodeLabel,
                        },
                      ]}
                      horizontal={true}
                      additionalClasses={'mb-4'}
                      disabled={isFormDisabled}
                      required
                      onChange={handleExecuteModeChange}
                      {...getTestProps(testId, 'execution-mode', 'testId')}
                    />
                  )}

                  {formik?.values?.executionMode !== 'code' && (
                    <Input
                      name="url"
                      type="text"
                      label={formik?.values?.type !== 'sync' ? 'URL' : ''}
                      value={formik?.values?.url}
                      onChange={formik.handleChange}
                      error={formik?.errors?.url || formik.status?.errors?.url}
                      additionalClasses={'mb-4'}
                      disabled={isFormDisabled}
                      required
                      {...getTestProps(testId, 'input-url', 'testId')}
                    />
                  )}

                  {formik?.values?.executionMode === 'code' && (
                    <div className="mb-4">
                      <CodeEditor
                        value={formik?.values?.code}
                        onChange={handleCodeChange}
                        options={{
                          readOnly: !codeWebhookAvaialable || isFormDisabled,
                          readOnlyMessage: {
                            value: isFormDisabled
                              ? ''
                              : t('Webhooks.Code.LimitsHostedWebhook'),
                          },
                        }}
                        className={twMerge(
                          !codeWebhookAvaialable && 'opacity-65',
                        )}
                        caption={monacoEditorCaption}
                        error={
                          formik?.errors?.code || formik.status?.errors?.code
                        }
                        {...getTestProps(testId, 'code-editor', 'testId')}
                      />
                    </div>
                  )}

                  <Switch
                    name={'enabled'}
                    checked={getIn(formik?.values, 'enabled')}
                    label={t('Webhooks.Enabled')}
                    onChange={formik.handleChange}
                    additionalClasses={'mb-4'}
                    disabled={isFormDisabled}
                    {...getTestProps(testId, 'switch-enabled', 'testId')}
                  />

                  {WebhookActionsFields}

                  <label className="flex text-sm text-slate-400 mt-4 mb-4">
                    {t('Webhooks.Headers')}
                  </label>

                  <SectionHeaders
                    headers={formik.values?.headers}
                    onRemove={handleRemoveHeaders}
                    onAdd={handleAddHeaders}
                    onChange={formik.handleChange}
                    errors={formik.errors.headers}
                    disabled={isFormDisabled}
                    {...getTestProps(testId, 'headers', 'testId')}
                  />

                  <Button
                    type="submit"
                    buttonSize="sm"
                    additionalClasses="m-auto w-max justify-center mt-4 hidden"
                    additionalChildrenClasses="flex flex-row items-center"
                    ref={refSubmitButton}
                    disabled={disableSave}
                    iconImage={
                      isLoading && <Loader size="small" type="spinner-grid" />
                    }
                    iconPosition="start"
                    {...getTestProps(testId, `button-submit`, 'testId')}
                  >
                    {t('Global.Save')}
                  </Button>
                </form>
              </ContentObjectFormContext.Provider>
            </FormikContext.Provider>
          </div>

          <div
            className="px-4 xl:pl-3.5 xl:pr-7 pb-7 border-t md:border-t-0 md:border-l dark:border-slate-800
            flex flex-col gap-5 w-full"
          >
            <ContentObjectInformations
              createdAt={duplicate ? null : formik?.values?.internal?.createdAt}
              updatedAt={duplicate ? null : formik?.values?.internal?.updatedAt}
              additionalClasses="order-10"
              testId={testId}
            />

            {mode === 'edit' && !duplicate && (
              <ContentObjectLogs
                logs={webhookLogs}
                loading={webhookLogsLoading}
                testId={testId}
                onRefresh={handleFetchLogs}
                additionalClasses="order-20"
              />
            )}

            {webhookVersions?.length > 0 && !duplicate && (
              <ContentObjectVersions
                versions={webhookVersions}
                testId={testId}
                selectVersionCallback={handleVersionSelectCallback}
                additionalClasses="order-30"
              />
            )}

            {pluginSidebarPanels?.length > 0 && (
              <ElementFromPlugin results={pluginSidebarPanels} />
            )}
          </div>
        </div>
      </div>
    </PageLayout>
  );
};

export default AddWebhooks;

AddWebhooks.propTypes = {
  /**
   * If is on duplicate
   */
  duplicate: PropTypes.bool,
  /**
   * Current mode "edit" or "add"
   */
  mode: PropTypes.string,
  /**
   * Test id for page
   */
  testId: PropTypes.string,
};

AddWebhooks.defaultProps = {
  mode: 'edit',
  duplicate: false,
  testId: '',
};
