import PropTypes from 'prop-types';

// :: Lib helpers
import { getTestProps } from '../../../lib/helpers';
import { CheckCircleIcon } from '../../../images/shapes';
import { twMerge } from 'tailwind-merge';

const TypeCardRow = ({ title, color, value, testId, additionalValueClass }) => {
  if (!title && !value) return false;

  const panelColor = {
    lime: 'lime',
    blue: 'blue',
    green: 'green',
    orange: 'orange',
    disabled: 'gray',
  };

  return (
    <div
      className="flex items-center gap-2 border-b border-slate-200 last:border-none dark:border-gray-800 py-1.5
      text-indigo-950 dark:text-white"
      {...getTestProps(testId, 'container')}
    >
      <CheckCircleIcon
        className={twMerge('w-2', `text-${panelColor[color]}`)}
      />
      {value && (
        <span
          className={twMerge('font-bold', additionalValueClass)}
          {...getTestProps(testId, 'value')}
        >
          {value}
        </span>
      )}
      {title && <span {...getTestProps(testId, 'title')}>{title}</span>}
    </div>
  );
};

export default TypeCardRow;

TypeCardRow.propTypes = {
  /**
   * Type card row title
   */
  title: PropTypes.string,
  /**
   * Type card row value
   */
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /**
   * Type card row test id
   */
  testId: PropTypes.string,
};

TypeCardRow.defaultProps = {
  title: '',
  value: '',
  testId: '',
  additionalValueClass: '',
};
