import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { PlusDoubleCircleIcon } from '../../../images/shapes';
import TopbarActionButton from './base/TopbarActionButton';

const DuplicateButton = ({ link, ...props }) => {
  const { t } = useTranslation();

  return (
    <TopbarActionButton
      link={link}
      label={t('Global.Duplicate')}
      iconImage={<PlusDoubleCircleIcon />}
      additionalIconClasses="w-5"
      {...props}
    />
  );
};

export default DuplicateButton;

DuplicateButton.propTypes = {
  /**
   * Link to duplicate page
   */
  link: PropTypes.string,
};

DuplicateButton.defaultProps = {
  link: '',
};
